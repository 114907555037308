export default
{
  UsersManagements: [],
  UsersManagement: {},

  DataModel: {},

  customerData:{},

  search:{},
}
