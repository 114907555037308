<template>
  <div>
    <div id="page-user-edit" class="flex">
      <!-- customer Details -->
      <div class="w-1/3 mr-4">
        <div class="handle-image-container">
          <img
            :src="baseURL + userData.ImagePath"
            class="handle-image"
            alt="User Image"
            style="width: 400px; height: 300px;"
          />
        </div>
        <vx-card class="mb-4" color="primary" elevation="2">
          <h3 class="card-title">{{ $t("UserInformation") }}</h3>
          <div class="card-content">
            <!-- Display User Information -->
            <div class="info-item">
              <i class="feather icon icon-user icon-blue"></i>
              <span class="info-text">{{ userData.NameAR }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-map-pin icon-blue"></i>
              <span class="info-text">{{ userData.AddressAR }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-phone icon-blue"></i>
              <span class="info-text" style="direction: ltr;">{{ userData.Phone }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-mail icon-blue"></i>
              <span class="info-text">{{ userData.Email }}</span>
            </div>
          </div>
        </vx-card>
      </div>

      <!-- Customer Service Requests -->
      <div class="w-2/3">
        <div id="data-list-list-view" class="data-list-container mb-4">
          <h3 class="section-title">{{ $t("CustomerAds") }}</h3>
          <vs-table v-if="userData.Ads && userData.Ads.length > 0" ref="table" id="Scroll" pagination :max-items="itemsPerPage" style="padding-top: 5px;" :data="userData.Ads">
            <template slot="thead">
              <vs-th style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("AdTitle") }}</vs-th>
              <vs-th style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("Description") }}</vs-th>
              <vs-th style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("AddressAr") }}</vs-th>
              <vs-th style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("ContactNumber") }}</vs-th>
              <vs-th style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("NewsDate") }}</vs-th>

            </template>
            <template slot-scope="{ data }">
              <tbody>
                <tr v-for="(tr, indextr) in data" :key="indextr" :class="{ 'selected-row': tr.selected }" @click="toggleRow(tr)">
                  <vs-td><p class="product-name">{{ tr.Title }}</p></vs-td>
                  <vs-td><p class="product-name">{{ tr.Description }}</p></vs-td>
                  <vs-td><p class="product-name">{{ tr.AddressAR }}</p></vs-td>
                  <vs-td><p class="product-name" style="direction: ltr;">{{ tr.ContactNumber }}</p></vs-td>
                  <vs-td><p class="product-name">{{ new Date(tr.CreatedDate).toLocaleDateString('en-GB') }}</p></vs-td>
                </tr>
              </tbody>
            </template>
          </vs-table>
          <div v-else class="no-data-message">
            <p>{{ $t("NoAds") }}</p>
          </div>
        </div>
      </div>
    </div>

   
  </div>
</template>


  <script>
  import VxCard from "../../components/vx-card/VxCard.vue";
  import { domain } from "@/gloabelConstant.js";
  import moduleUsersManagement from "@/store/UsersManagement/Customer/moduleUsersManagement.js";

  export default {
    components: {
      VxCard
    },
    data() {
      return {
        baseURL: domain,
        user_not_found: false,
        activeTab: 0,
        customerServices:[],
        service:{},
        itemsPerPage:5,
        rating: 4.5,
    reviewCount: 10,

      };
    },
    computed:{
      userData(){
        debugger;
        return this.$store.state.UsersManagementList.customerData;

      },

    },
    methods: {
      formatDate(dateString) {
    const date = new Date(dateString);
    // Return formatted date as "YYYY-MM-DD" (or any other desired format)
    return date.toLocaleDateString('en-GB'); // For DD/MM/YYYY format, or adjust for your locale
  },
      getIcon(key) {
        switch (key) {
          case "ownerName":
            return "icon-user";
          case "address":
            return "icon-map-pin";
          case "phoneNumber":
            return "icon-phone";
          case "Email":
            return "icon-mail";
          case "status":
            return "icon-check-circle";
          case "nationalCardNumber":
            return "icon-credit-card";
          default:
            return "";
        }
      }
    ,

    },
    created() {
      debugger;
      if (!moduleUsersManagement.isRegistered) {
        this.$store.registerModule("UsersManagementList", moduleUsersManagement);
      moduleUsersManagement.isRegistered = true;

    }
    const x=this.$route.params.Id;
    this.$store.dispatch("UsersManagementList/fetchUserItems", x)
    },
  };
  </script>


<style scoped>
  .flex {
    display: flex;
  }

  .w-1\3 {
    width: 33.33%;
    margin-right: 20px; /* Adjust spacing */
  }

  .w-2\3 {
    width: 66.66%;
  }
  .no-data-message {
  text-align: center;
  padding: 20px;
  color: green;
  }
  .handle-image-container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 20px; /* Adjust spacing */
  }

  .handle-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .vx-card {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px; /* Adjust spacing */
  }

  .card-title {
    padding-bottom: 20px;
  }

  .card-content {
    margin-bottom: 10px;
  }

  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .info-item .feather {
    margin-right: 10px;
    color: #2196F3;
  }

  .data-list-container {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 20px; /* Adjust spacing */
  }

  .section-title {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .selected-row {
    background-color: #f0f0f0;
    cursor: pointer;
  }
</style>



