export default {
  ADD_ITEM(state, item) {
    debugger;
    state.AdsManagements.unshift(item);
  },
  SET_UsersManagements(state, UserManagements) {
    debugger;
    state.UsersManagements = UserManagements;
  },
  UPDATE_UsersManagement(state, AdsManagement) {
    const AdsManagementIndex = state.AdsManagements.findIndex(p => p.ID == AdsManagement.ID);
    if (AdsManagementIndex != -1) {
      Object.assign(state.AdsManagements[AdsManagementIndex], AdsManagement);
    }
  },

  UPDATE_Broker(state,UsersManagement) {
    debugger
    const BrokerIndex = state.UsersManagement.findIndex((p) => p.ID == UsersManagement.Id)
    Object.assign(state.UsersManagement[BrokerIndex], UsersManagement)
  },
  SET_BROKER_DATA(state, UsersManagement){
    debugger;
    state.UsersManagement=UsersManagement
  },
  UPDATE_BROKER_DATA(state, DataModel){
    debugger;
    state.DataModel=DataModel
  },

  SET_ServiceProvider_DATA(state, UsersManagement){
    debugger;
    state.UsersManagement=UsersManagement
  },
  SET_User_DATA(state, customerData){
    debugger;
    state.customerData =customerData
  },
  REMOVE_ITEM(state, itemId) {
    debugger;
    const ItemIndex = state.UsersManagements.findIndex(p => p.ID == itemId);
    state.UsersManagements.splice(ItemIndex, 1);
  }
};
