import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Broker/AddBroker", item)
        .then(response => {
          resolve(response);
          commit("ADD_ITEM", Object.assign(item, { ID: response.data.data.ID }));
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  // GetBrokerItem({ commit },id){
  //   debugger;
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`API/Broker/GetById/${id}`)
  //       .then(response => {
  //         debugger;
  //         resolve(response);
  //        console.log(response)
  //         commit("SET_BROKER_DATA", response.data.data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // GetBrokerModel({ commit },id){
  //   debugger;
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`API/Broker/GetBrokerModel/${id}`)
  //       .then(response => {
  //         debugger;
  //         resolve(response);
  //        console.log(response)
  //         commit("UPDATE_BROKER_DATA", response.data.data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // fetchServiceProviderItems({ commit },id){
  //   debugger;
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`API/ServicesProvider/GetById/${id}`)
  //       .then(response => {
  //         resolve(response);
  //        console.log(response)
  //         commit("SET_ServiceProvider_DATA", response.data.data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },


  fetchUserItems({ commit },Id){

    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get(`API/Customer/GetCustomerById/${Id}`,{headers:Token})
        .then(response => {
          resolve(response);
         console.log(response)
          commit("SET_User_DATA", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/Customer/GetAllUsers",{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_UsersManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchUsersData({ commit }, search) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      
      axios
        .post("API/Customer/SearchCustomerData", search,{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_UsersManagements", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
 
  updateItem(context, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/Broker/Update", item,{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  

  UpdateBroker({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/Broker/Update", item,{headers:Token})
        .then(response => {
          resolve(response);
          commit("UPDATE_Broker", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // GetItemByID(context, itemid) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //     .get("API/UnitOwner/GetUnitOwnerById?Id=" + itemid)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  // GetUnitOwnersNotApproved() {
  //   return new Promise((resolve, reject) => {
  //     debugger;
  //     axios
  //     .get("API/UnitOwner/GetNewOwnersCount")
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  GetOwnersNotApproved() {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitOwner/GetUnitOwnersNotApproved",{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteCustomer({ commit }, Id) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .delete("API/Customer/DeleteCustomer?Id=" +Id,{headers:Token})
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", Id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  BlockCustomer(context,Id) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/Customer/BlockCustomer?Id=" +Id,{headers:Token})
        .then(response => {
          resolve(response);
          
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  
  UnBlockCustomer(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/Customer/UnBlockCustomer?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  ApprovalRequest(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/Authentication/AcceptRegistrationRequests?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CancelRequest({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .delete("API/UnitOwner/DeleteUnitOwnr?Id=" + item)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
