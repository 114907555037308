
import state from "./moduleUsersManagementState.js"
import mutations from "./moduleUsersManagementMutations.js"
import getters from "./moduleUsersManagementGetters.js"
import actions from './moduleUsersManagementActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
